import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import "../assets/css/services.css";
import { useNavigate } from "react-router-dom";
import ecomFulfillment from "../assets/imgs/ecomFfImg.png";
import customClearance from "../assets/imgs/customClearance.png";
import freightForwarding from "../assets/imgs/ffImg.png";
import warehousing from "../assets/imgs/warehousingImg.png";

const serviceLinks = [
  { name: "E-commerce fulfillment", count: 7 },
  { name: "Freight Forwarding", count: 3 },
  { name: "Custom Clearance", count: 3 },
  { name: "Warehousing", count: 3 },
];

const ServiceBox1 = () => (
  <Box
    className="service-box"
    sx={{
      width: 370,
      height: 317,
      backgroundImage: `url(${ecomFulfillment})`,
      opacity: "0.8",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundColor: "whitesmoke",
      borderRadius: "8px",
    }}
  >
    <Typography
      variant="h2"
      sx={{
        color: "#391400",
        fontWeight: "800",
        fontSize: "40px",
        textAlign: "left",
        paddingLeft: "20px",
        paddingBottom: "30px",
        textShadow: "1px 1px 1px #ffffff",
      }}
    >
      E-commerce Fulfillment
    </Typography>
  </Box>
);

const ServiceBox2 = () => (
  <Box
    className="service-box"
    sx={{
      width: 570,
      height: 317,
      backgroundImage: `url(${freightForwarding})`,
      opacity: "0.8",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundColor: "whitesmoke",
      borderRadius: "8px",
    }}
  >
    <Typography
      variant="h2"
      sx={{
        color: "#391400",
        fontWeight: "800",
        fontSize: "40px",
        textAlign: "left",
        paddingLeft: "20px",
        paddingBottom: "30px",
        textShadow: "1px 1px 1px #ffffff",
      }}
    >
      Freight Forwarding
    </Typography>
  </Box>
);

const ServiceBox3 = () => (
  <Box
    className="service-box"
    sx={{
      width: 570,
      height: 317,
      backgroundImage: `url(${customClearance})`,
      opacity: "0.8",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundColor: "whitesmoke",
      borderRadius: "8px",
    }}
  >
    <Typography
      variant="h2"
      sx={{
        color: "#391400",
        fontWeight: "800",
        fontSize: "40px",
        textAlign: "left",
        paddingLeft: "20px",
        paddingBottom: "30px",
        textShadow: "1px 1px 1px #ffffff",
      }}
    >
      Custom Clearance
    </Typography>
  </Box>
);

const ServiceBox4 = () => (
  <Box
    className="service-box"
    sx={{
      width: 370,
      height: 317,
      backgroundImage: `url(${warehousing})`,
      opacity: "0.8",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundColor: "whitesmoke",
      borderRadius: "8px",
    }}
  >
    <Typography
      variant="h2"
      sx={{
        color: "#391400",
        fontWeight: "800",
        fontSize: "40px",
        textAlign: "left",
        paddingLeft: "20px",
        paddingBottom: "30px",
        textShadow: "1px 1px 1px #ffffff",
      }}
    >
      Warehousing
    </Typography>
  </Box>
);

const Services = () => {
  const navigate = useNavigate();
  const handleReadMoreClick = () => {
    navigate("/services");
  };

  const handleServiceClick = (service) => {
    navigate(`/services/${service}`);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#001E3C",
        color: "white",
        py: 10,
        px: 3,
        textAlign: "center",
        paddingTop: "200px",
      }}
    >
      <Typography sx={{ color: "#FF6F61" }} className="subtitle">
        SERVICES
      </Typography>
      <Typography variant="h3" sx={{ fontWeight: "bold", mb: 4 }}>
        What we offer
      </Typography>

      <Box display="flex" justifyContent="center" mb={6}>
        {serviceLinks.map((service, index) => (
          <Link
            key={index}
            // href="/services"
            href={"/services#"+service.name}
            target="_self"
            className="redirect-to-servicesPageButton"
            // onClick={handleReadMoreClick}
            cursor="pointer"
            underline="none"
            sx={{
              color: "white",
              mx: 2,
              fontSize: "1.1rem",
              fontWeight: 300,
              opacity: 0.7,
            }}
          >
            {service.name}
            <sup style={{ fontSize: "0.7rem" }}>{service.count}</sup>
          </Link>
        ))}
      </Box>

      <Grid
        container
        spacing={1}
        justifyContent="center"
        sx={{ margin: "70px 0px" }}
      >
        <Grid item>
          <ServiceBox1 />
        </Grid>
        <Grid item>
          <ServiceBox2 />
        </Grid>
        <Grid item>
          <ServiceBox3 />
        </Grid>
        <Grid item>
          <ServiceBox4 />
        </Grid>
      </Grid>

      <Box mt={6}>
        <Button
          variant="outlined"
          className="redirect-to-servicesPageButton"
          onClick={handleReadMoreClick}
          sx={{
            color: "white",
            borderColor: "white",
            borderRadius: 2,
            px: 4,
            mb: "200px",
            "&:hover": {
              backgroundColor: "#f9f9f9",
              color: "#001E3C",
              borderColor: "#001E3C",
            },
          }}
        >
          READ MORE
        </Button>
      </Box>
    </Box>
  );
};

export default Services;
