import React, { useState } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import "../assets/css/servicespage.css";
import BlogPostCard from "../components/BlogPostCard";
import blog1Image from "../assets/imgs/blog1Image.jpg";
import blog2Image from "../assets/imgs/blog2Image.jpg";
import blog3Image from "../assets/imgs/blog3Image.jpg";
import blog4Image from "../assets/imgs/blog4Image.jpg";
import blog5Image from "../assets/imgs/blog5Image.png";
import blog6Image from "../assets/imgs/blog6Image.jpg";
import blog7Image from "../assets/imgs/blog7Image.png";
import blog8Image from "../assets/imgs/blog8Image.jpg";
import blog9Image from "../assets/imgs/blog9Image.jpg";
import blog10Image from "../assets/imgs/blog10Image.jpg";
import blog11Image from "../assets/imgs/blog11Image.jpg";
import blog12Image from "../assets/imgs/blog12Image.png";
import blog13Image from "../assets/imgs/blog13Image.png";
import blog14Image from "../assets/imgs/blog14Image.png";
import { Link, useNavigate } from "react-router-dom";

const blogs = [
  {
    id: 1,
    title: "The Role of 4PL in Modern Supply Chain Management",
    subtitle: "How 4PL Solutions Transform Logistics for Businesses",
    img: blog4Image,
    date: "7 Dec, 2021",
    category: "Supply Chain",
  },
  {
    id: 2,
    title: "E-Commerce Fulfillment: Meeting Customer Expectations in 2024",
    subtitle: "Key Strategies for Faster and Smarter Fulfillment",
    img: blog5Image,
    date: "25 Nov, 2021",
    category: "E-Commerce Fulfillment",
  },
  {
    id: 3,
    title:
      "Freight Forwarding Demystified: Choosing the Right Mode for Your Business",
    subtitle: "Air, Ocean, or Land? A Guide to Freight Solutions",
    img: blog3Image,
    date: "5 Nov, 2021",
    category: "Freight Forwarding",
  },
  {
    id: 4,
    title: "The Importance of Warehousing in a Scalable Supply Chain",
    subtitle: "How Smart Warehousing Supports Business Growth",
    img: blog6Image,
    date: "29 Oct, 2021",
    category: "Warehousing",
  },
  {
    id: 5,
    title:
      "Customs Clearance Made Simple: Tips for Hassle-Free Imports and Exports",
    subtitle: "Navigating International Trade with Confidence",
    img: blog7Image,
    date: "21 Oct, 2021",
    category: "Customs Clearance",
  },
  {
    id: 6,
    title: "Top 5 Benefits of Partnering with a 4PL Provider",
    subtitle: "Why 4PL Solutions Are Essential for Growing Businesses",
    img: blog8Image,
    date: "17 Nov, 2021",
    category: "Logistics Management",
  },
  {
    id: 7,
    title: "E-Commerce Success: The Role of Efficient Returns Management",
    subtitle: "Turning Returns into a Competitive Advantage",
    img: blog9Image,
    date: "12 Oct, 2021",
    category: "E-Commerce Fulfillment",
  },
  {
    id: 8,
    title:
      "Sustainability in Freight Forwarding: Reducing the Carbon Footprint",
    subtitle: "Eco-Friendly Logistics for a Greener Future",
    img: blog10Image,
    date: "9 Oct, 2021",
    category: "Sustainability",
  },
  {
    id: 9,
    title: "The Future of Warehousing: Trends and Technologies to Watch",
    subtitle: "How Automation and AI Are Shaping Warehousing",
    img: blog11Image,
    date: "1 Oct, 2021",
    category: "Warehousing",
  },
  {
    id: 10,
    title:
      "The Challenges of Cross-Border Shipping and How 4PL Providers Solve Them",
    subtitle: "Overcoming Logistics Barriers in Global Trade",
    img: blog12Image,
    date: "15 Sep, 2021",
    category: "Global Trade",
  },
  {
    id: 11,
    title: "How to Choose the Right 4PL Partner for Your Business",
    subtitle: "Key Factors to Consider for a Successful Partnership",
    img: blog13Image,
    date: "1 Sep, 2021",
    category: "Business Growth",
  },
  {
    id: 12,
    title: "Inventory Management Best Practices for E-Commerce Businesses",
    subtitle: "Achieving Accuracy and Efficiency in Fulfillment",
    img: blog14Image,
    date: "20 Aug, 2021",
    category: "E-Commerce Fulfillment",
  },
  {
    id: 13,
    title: "Why 4PL is the Future of Supply Chain Management",
    subtitle: "Revolutionizing Logistics with End-to-End Solutions",
    img: blog1Image,
    date: "15 Nov, 2021",
    category: "Supply Chain",
  },
  {
    id: 14,
    title:
      "The E-Commerce Fulfillment Formula: Speed, Accuracy, and Customer Satisfaction",
    subtitle: "How SYM International Helps E-Commerce Businesses Thrive",
    img: blog2Image,
    date: "20 Aug, 2021",
    category: "E-Commerce Fulfillment",
  },
];

const RelatedArticles = ({ blogId }) => {
    const navigate=useNavigate();

  // Filter blogs to find related articles (same category and not the current blog)
  const currentBlog = blogs.find((blog) => blog.id == blogId);
  // Safely get the category or default to null if the blogId is invalid
  const category = currentBlog ? currentBlog.category : null;

  const relatedBlogs = blogs
    .filter((blog) => blog.category == category && blog.id != blogId)
    .slice(0, 3); // Limit to 3 blogs

    const handleBlogClick = (blog) => {
        navigate(`/blogs/${blog.id}`, { state: blog });
      };
    
  return (
    <Box sx={{ padding: "2rem 0" }}>
      {/* Heading */}
      {console.log("id: " + blogId + " category: " + category)}
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", marginBottom: "1rem", color: "#003366" }}
      >
        Related Articles
      </Typography>

      {/* Blog Cards */}
      <Grid container spacing={4}>
        {relatedBlogs.length > 0 ? (
          relatedBlogs.map((blog) => (
            <Grid item xs={12} sm={6} md={4} key={blog.id} onClick={()=>handleBlogClick(blog)} style={{cursor:'pointer', margin:'10px 0'}}>
              {/* Blog Card */}
              <Box
                sx={{
                  backgroundColor: "#ffffff",
                  backgroundImage: `url(${blog.img})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  padding: "1rem",
                  textAlign: "center",
                  borderRadius: "8px",
                  height: "200px",
                }}
              ></Box>

              {/* Blog Details */}
              <div
                className="all-blogs-content"
                style={{ paddingTop: "20px", paddingLeft: "10px", textAlign:"left" }}
              >
                <Typography variant="subtitle2" sx={{ color: "#391400", opacity:"0.8" }}>
                  {blog.subtitle}
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", margin: "0.5rem 0", color: "#391400" }}
                >
                  {blog.title}
                </Typography>
                <Typography variant="body2" sx={{ color: "#391400", opacity:"0.7" }}>
                  {blog.date}
                </Typography>
              </div>
            </Grid>
          ))
        ) : (
          // Show this if no related blogs are found
          <Typography variant="body1" sx={{ color: "#6B7280", paddingTop:"20px", paddingLeft:"35px" }}>
            No related articles available.
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default RelatedArticles;
