import React from "react";
import { Row, Col } from "react-grid-system";
import { Box, Typography, Button } from "@mui/material";
// import blog1Image from "../assets/imgs/blog1Image.jpg";
import blog1Image from "../assets/imgs/blog1Image.jpg";
import blog2Image from "../assets/imgs/blog2Image.jpg";
import blog3Image from "../assets/imgs/blog3Image.jpg";
import { Link, useNavigate } from "react-router-dom";

const MainBlog = () => {
  const navigate=useNavigate();

  const handleBlogClick = (blog) => {
    navigate(`/blogs/${blog}`);
  };

  return (
    <Box style={{ backgroundColor: "#E6F0FF", padding: "40px", paddingBottom:'150px' }}>
      <Row>
        {/* Blog Header and Discover Button */}
        <Col md={9}>
          <Typography
            variant="overline"
            style={{
              color: "#EF6D58",
              fontWeight: "bold",
              fontSize: "14px",
              textTransform: "uppercase",
            }}
          >
            Our Blog
          </Typography>
          <Typography
            variant="h3"
            style={{
              fontWeight: "bold",
              color: "#333333",
              marginBottom: "20px",
            }}
          >
            Latest Blog Articles
          </Typography>
        </Col>
        <Col md={3} style={{ textAlign: "right" }}>
        <Link
            to="/blogs"
            style={{ textDecoration: "none", color: "whitesmoke" }}
          >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#ffffff",
              color: "#333333",
              fontWeight: "bold",
              padding: "10px 20px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              borderRadius:'35px',
              textTransform: "uppercase",
            }}
          >
            Discover All
          </Button>
          </Link>
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        {/* Main Blog Article */}
        <Col md={8} onClick={()=>handleBlogClick("13")} style={{cursor:'pointer', margin:'10px 0'}}>
          <Box
            style={{
              backgroundColor: "#ffffff",
              backgroundImage: `url(${blog1Image})`,
              backgroundSize: "750px auto",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              // height: "200px",
              // borderRadius: "8px",
              // marginBottom: "16px",
              height: "600px",
              borderRadius: "8px",
              marginBottom: "16px",
            }}
          ></Box>
          <Typography variant="subtitle2" style={{ color: "#888888" }}>
            Supply Chain Management
          </Typography>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              fontSize:'30px',
              color: "#333333",
              marginBottom: "8px",
            }}
          >
            Why 4PL is the Future of Supply Chain Management
          </Typography>
          <Typography variant="caption" style={{ color: "#888888" }}>
            15 Nov, 2021
          </Typography>
        </Col>

        {/* Smaller Blog Articles */}
        <Col md={4}>
          <Box style={{ marginBottom: "20px", cursor:'pointer' }} onClick={()=>handleBlogClick("14")}>
            <Box
              style={{
                backgroundColor: "#ffffff",
              backgroundImage: `url(${blog2Image})`,
              backgroundSize: "auto 250px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
                height: "210px",
                borderRadius: "8px",
                marginBottom: "8px",
              }}
            ></Box>
            <Typography variant="subtitle2" style={{ color: "#888888" }}>
              E-commerce Fulfillment
            </Typography>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "#333333",
                marginBottom: "4px",
              }}
            >
              The E-Commerce Fulfillment Formula: Speed, Accuracy, and
              Customer Satisfaction
            </Typography>
            <Typography variant="caption" style={{ color: "#888888" }}>
              20 Aug, 2021
            </Typography>
          </Box>

          <Box onClick={()=>handleBlogClick("3")} style={{cursor:'pointer', margin:'10px 0'}}>
            <Box
              style={{
                backgroundColor: "#ffffff",
              backgroundImage: `url(${blog3Image})`,
              backgroundSize: "auto 250px",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
                height: "210px",
                borderRadius: "8px",
                marginBottom: "8px",
              }}
            ></Box>
            <Typography variant="subtitle2" style={{ color: "#888888" }}>
            Freight Forwarding
            </Typography>
            <Typography
              variant="h6"
              style={{
                fontWeight: "bold",
                color: "#333333",
                marginBottom: "4px",
              }}
            >
              Freight Forwarding Demystified: Choosing the Right Mode for Your Business
            </Typography>
            <Typography variant="caption" style={{ color: "#888888" }}>
              05 Nov, 2021
            </Typography>
          </Box>
        </Col>
      </Row>
    </Box>
  );
};

export default MainBlog;
