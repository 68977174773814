import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

const BlogPostCard = ({ title, subtitle, image, author, date, category }) => {
  return (
    <Card style={{backgroundColor:"none"}}>
      {image && <CardMedia component="img" height="330" image={image} alt={title} />}
      <CardContent style={{backgroundColor:"none"}}>
      {/* <Typography variant="body2" color="text.secondary" sx={{opacity:"0.8", fontSize:'12px', fontWeight:'light'}}>
          {category}
        </Typography> */}
      <Typography variant="body2" color="text.secondary" paragraph>
          {subtitle}
        </Typography>
        <Typography variant="h6" component="div" sx={{ fontWeight: "bold", margin: "0.5rem 0" }}>
          {title}
        </Typography>
        
        <Typography variant="body2" sx={{ color: "#9CA3AF" }}>
          By {author} on {new Date(date).toLocaleDateString()}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default BlogPostCard;
