import React, {useRef} from 'react';
import facebook from '../assets/imgs/facebook-icon.png'; 
import instagram from '../assets/imgs/instagram-icon.png'; 
import twitter from '../assets/imgs/twitter-icon.png'; 

import '../assets/css/footer.css';

function Footer() {

    const aboutUsRef = useRef(null);

    const scrollToAboutUs = () => {
      if (aboutUsRef.current) {
        aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };
  

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-section">
                    <h2 className="footer-title">SYM INTERNATIONAL</h2>
                </div>
                
                <div className="footer-section">
                    <h3 className="footer-heading">MENU</h3>
                    <ul className="footer-list">
                        <li><a href="/aboutus" className="footer-link">About Us</a></li>
                        <li><a href="/services" className="footer-link">Services</a></li>
                        <li><a href="/blogs" className="footer-link">Blogs</a></li>
                        <li><a href="/contactus" className="footer-link">Contact Us</a></li>
                    </ul>
                </div>
                
                <div className="footer-section">
                    <h3 className="footer-heading">SERVICES</h3>
                    <ul className="footer-list">
                        <li><a href="/services" className="footer-link">E-commerce Fulfillment</a></li>
                        <li><a href="/services" className="footer-link">Freight Forwarding</a></li>
                        <li><a href="/services" className="footer-link">Custom Clearance</a></li>
                        <li><a href="/services" className="footer-link">Warehousing</a></li>
                    </ul>
                </div>
                
                <div className="footer-section social-media">
                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                    <img src={facebook} alt="facebook-logo" className='social-icon' />
                    </a>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                    <img src={twitter} alt="twitter-logo" className='social-icon' />

                    </a>
                    <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                    <img src={instagram} alt="instagram-logo" className='social-icon' />

                    </a>
                </div>
            </div>

            <div className="footer-bottom">
                <p className="footer-copyright">Copyright © 2024. All Rights Reserved.</p>
                <div className="footer-links">
                    <a href="/terms" className="footer-bottom-link">Terms of Use</a>
                    &nbsp;&nbsp;
                    <a href="/privacy" className="footer-bottom-link">Privacy Policy</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
