import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from 'react-router-dom';


const faqs = [
  {
    question:
      "Q. How does SYM International streamline e-commerce fulfillment?",
    answer:
      "We handle everything from inventory management and order processing to packaging and last-mile delivery. Our advanced systems ensure accurate tracking, faster deliveries, and a seamless experience for your customers.",
  },
  {
    question:
      "Q. Can SYM International assist with complex freight forwarding needs?",
    answer:
      "Absolutely! We provide comprehensive freight forwarding services, managing air, sea, and land shipments while ensuring timely and cost-effective transportation for your goods.",
  },
  {
    question:
      "Q. What are the benefits of using SYM International's warehousing services?",
    answer:
      "Our secure, strategically located warehouses provide scalable storage, real-time inventory management, and seamless integration with your supply chain, helping you reduce costs and improve efficiency.",
  },
  {
    question:
      "Q. How does SYM International ensure hassle-free customs clearance?",
    answer:
      "Our experienced customs team handles all paperwork, regulations, and compliance requirements, ensuring your shipments clear customs quickly and efficiently, with minimal delays or disruptions.",
  },
];

const FAQs = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        p: 4,
        backgroundColor: "#E3F2FD",
        pt: 10,
        pb: 25,
      }}
    >
      {/* Left Side with Title and Contact Button */}
      <Box sx={{ flex: 1, mr: 18, pl: 5 }}>
        <Typography
          sx={{ color: "#e57373", textTransform: "uppercase", mb: 1 }}
        >
          FAQ
        </Typography>
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", color: "#3C1D00", mb: 1 }}
        >
          Frequently Asked Questions
        </Typography>
        <Typography variant="body1" sx={{ color: "#3C1D00", mb: 3, opacity:'0.8', textAlign:'justify' }}>
          Explore answers to frequently asked questions about SYM
          International's 4PL services and how we streamline your logistics
          needs.
        </Typography>
        <Link to="/contactus" style={{ textDecoration: 'none', color:'whitesmoke' }}>
        <Button
          variant="outlined"
          sx={{
            color: "#3C1D00",
            borderColor: "#3C1D00",
            pt: 1,
            pb: 1,
            pl: 2,
            pr: 2,
          }}
        >
          Contact Us
        </Button>
        </Link>
      </Box>

      {/* Right Side with Accordion */}
      <Box sx={{ flex: 1.5 }} border={"1px solid rgba(229, 115, 115, 0.3)"}>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={handleChange(index)}
            sx={{
              mb: 1,
              boxShadow: 2,
              "&:before": { display: "none" }, // Remove default accordion border
              backgroundColor: expanded === index ? "#FFFFFF" : "#E3F2FD",
              padding: 3,
            }}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: "#FFFFFF",
                    backgroundColor: "#EF6D58",
                    borderRadius: 5,
                    padding: 0.7,
                  }}
                />
              }
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
              sx={{
                backgroundColor: expanded === index ? "#FFFFFF" : "#E3F2FD",
                color: "#3C1D00",
                fontWeight: "bold",
              }}
            >
              <Typography style={{ fontSize: "22px", fontWeight: "bold" }}>
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: "#FFFFFF" }}>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default FAQs;
