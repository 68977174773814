// src/components/ContactForm.js
import React, { useState } from "react";
import axios from "axios";
import { Box, TextField, Button, Typography } from "@mui/material";
import { Row, Col } from "react-grid-system";
import { Link } from "react-router-dom";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("http://localhost:5000/send-email", formData);
      alert("Message sent successfully!");
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message.");
    }
  };

  return (
    <div style={{ backgroundColor: "#E6F0FF", padding: "30px 40px 20px" }}>
      <Box py={6} style={{ backgroundColor: "#E6F0FF", padding: "40px" }}>
        <Row gutterWidth={40}>
          {/* Left Column - Contact Form */}
          <Col md={6}>
            <Box
              style={{
                backgroundColor: "#ffffff",
                padding: "55px 50px",

                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography
                variant="h4"
                style={{
                  fontWeight: "bold",
                  marginBottom: "20px",
                  color: "#333333",
                }}
              >
                Get In Touch
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Your Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ style: { color: "#888888" } }}
                />
                <TextField
                  label="Subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{ style: { color: "#888888" } }}
                />
                <TextField
                  label="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  variant="outlined"
                  InputLabelProps={{ style: { color: "#888888" } }}
                />
                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    marginTop: "20px",
                    marginLeft: "337px",
                    backgroundColor: "#EF6D58",
                    color: "#ffffff",
                    padding: "10px 20px",
                    textTransform: "uppercase",
                  }}
                >
                  Submit Now
                </Button>
              </form>
            </Box>
          </Col>

          {/* Right Column - Contact Information */}
          <Col
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: "60px",
            }}
          >
            <Typography
              variant="overline"
              style={{ color: "#EF6D58", fontWeight: "bold", fontSize: "14px" }}
            >
              Contact
            </Typography>
            <Typography
              variant="h3"
              style={{
                fontWeight: "bold",
                color: "#023079",
                marginBottom: "120px",
              }}
            >
              Contact us
            </Typography>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <span
                style={{
                  color: "#EF6D58",
                  fontSize: "20px",
                  marginRight: "10px",
                }}
              >
                📍
              </span>
              <Link to="https://maps.app.goo.gl/j6aYTwnXGAaAzPqy7" style={{textDecoration:"none"}}>
              
              <Typography variant="body1" style={{ color: "#005DBC" }}>
                46735 Fremont Blvd, Fremont, CA - 94538
              </Typography>
              </Link>
              
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >
              <span
                style={{
                  color: "#EF6D58",
                  fontSize: "20px",
                  marginRight: "10px",
                }}
              >
                📞
              </span>
              <Typography variant="body1" style={{ color: "#005DBC" }}>
              <a href="tel:+1-415-605-6697" style={{textDecoration:"none", color: "#005DBC" }}>(+1) 415 605 6697</a>
                
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  color: "#EF6D58",
                  fontSize: "20px",
                  marginRight: "10px",
                }}
              >
                ✉️
              </span>
              <Typography variant="body1">
                <a
                  href="mailto:syed@symintl.com?"
                  style={{ textDecoration: "none", color: "#005DBC" }}
                >
                  syed@symintl.com
                </a>
              </Typography>
            </div>
          </Col>
        </Row>
      </Box>
    </div>
  );
};

export default ContactForm;

// // src/components/ContactForm.js
// import React, { useState } from "react";
// import axios from "axios";
// import { Box, TextField, Button, Typography } from "@mui/material";

// const ContactForm = () => {
//   const [formData, setFormData] = useState({
//     email: "",
//     subject: "",
//     message: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.post("http://localhost:5000/send-email", formData);
//       alert("Message sent successfully!");
//     } catch (error) {
//       console.error("Error sending message:", error);
//       alert("Failed to send message.");
//     }
//   };

//   return (
//     <div className="row">
//       <Box py={6} textAlign="center" className="column">
//         <Typography variant="h4">Get In Touch</Typography>
//         <form
//           onSubmit={handleSubmit}
//           style={{ maxWidth: 400, margin: "0 auto" }}
//         >
//           <TextField
//             label="Your Email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             fullWidth
//             margin="normal"
//           />
//           <TextField
//             label="Subject"
//             name="subject"
//             value={formData.subject}
//             onChange={handleChange}
//             fullWidth
//             margin="normal"
//           />
//           <TextField
//             label="Message"
//             name="message"
//             value={formData.message}
//             onChange={handleChange}
//             fullWidth
//             margin="normal"
//             multiline
//             rows={4}
//           />
//           <Button variant="contained" color="primary" type="submit">
//             Submit Now
//           </Button>
//         </form>
//       </Box>
//       <Box py={6} textAlign="left"  className="column">
//       <Typography variant="h3" >Contact us</Typography>
//       </Box>
//     </div>
//   );
// };

// export default ContactForm;
