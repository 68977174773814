import React, { useState } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import "../assets/css/servicespage.css";
import Hero from "../components/hero/heroComponent";
import TestimonialsSlider from "../components/TestimonialsSlider";
// import Testimonials from "../components/Testimonials";
import FAQs from "../components/FAQs";
import Footer from "../components/Footer";
// import robotImage from "../assets/imgs/homepage-header.png";
import ecomFfImg from "../assets/imgs/ecomFfImg.png";
import customClearance from "../assets/imgs/customClearance.png";
import ffImg from "../assets/imgs/ffImg.png";
import warehousingImg from "../assets/imgs/warehousingImg.png";
import peekingCartoon from "../assets/imgs/peekingCartoon.png";


const ServicesPage = () => {
  const [showItems1, setShowItems1] = useState(false);
  const [showItems2, setShowItems2] = useState(false);
  const [showItems3, setShowItems3] = useState(false);
  const [showItems4, setShowItems4] = useState(false);
  const toggleItems1 = () => {
    setShowItems1(!showItems1);
  };
  const toggleItems2 = () => {
    setShowItems2(!showItems2);
  };
  const toggleItems3 = () => {
    setShowItems3(!showItems3);
  };
  const toggleItems4 = () => {
    setShowItems4(!showItems4);
  };

  return (
    <div className="container-fluid" style={{ backgroundColor: "#E3F2FD" }}>
      {/* Hero Section */}
      <Hero heading="Services" />

      {/* What we offer Section */}
      <Box
        sx={{
          padding: "60px 100px",
          backgroundColor: "#E3F2FD",
          position: "relative",
        }}
      >
        <Typography
          variant="h6"
          style={{
            position: "absolute",
            top: "45px",
            left: "130px",
            fontSize: "16px",
            fontWeight: "300",
          }}
          className="subtitle"
          gutterBottom
        >
          SERVICES<br></br>
        </Typography>
        <Typography
          variant="h3"
          style={{ position: "absolute", top: "80px", left: "130px" }}
          fontWeight="1000"
          color="#391400"
          letterSpacing="-2px"
          width="220px"
        >
          What we offer
        </Typography>
        <Typography
          variant="h6"
          style={{
            position: "absolute",
            top: "120px",
            left: "500px",
            fontSize: "18px",
            opacity: "0.75",
          }}
          fontWeight="300"
          color="#391400"
          letterSpacing="-1px"
          maxWidth="750px"
        >
          Our company offers a wide range of services, including logistics,
          transportation, and supply chain management. Our team of experienced
          professionals will help you optimize your operations and achieve your
          business goals.
        </Typography>
      </Box>

      {/* Our Services Section */}
      <Box
        sx={{
          padding: "80px 100px",
          textAlign: "center",
          backgroundColor: "#E3F2FD",
        }}
      >
        <div className="heading-container" style={{paddingBottom: "90px", marginBottom:'10px', borderBottom:'1px solid darkblue'}}>
        <Typography
          variant="h3"
          fontWeight="1000"
          color="#391400"
          sx={{
            letterSpacing: "-2px",
            
            paddingTop: "85px",
          }}
        >
          Our Services
        </Typography>
        {/* <img src={peekingCartoon} alt="peeking cartoon image" style={{maxWidth:'250px', height:'200px', padding:'0px'}}></img> */}
        </div>
        <div className="services-container">
          <table>
            <tbody>
              <tr id="E-commerce fulfillment">
                <td style={{ verticalAlign: "top", paddingTop: "50px" }}>
                  <img src={ecomFfImg} alt="ecommerce fulfilment image" style={{maxHeight:'400px'}}></img>
                </td>
                <td>
                <Typography
                    variant="h3"
                    fontWeight="900"
                    fontSize="43px"
                    textAlign="left"
                    color="#391400"
                    sx={{ marginBottom: "20px" }}
                  >
                    E-commerce Fulfillment
                  </Typography>
                  <Typography
                    variant="body1"
                    color="#391400"
                    textAlign="justify"
                    paddingTop="30px"
                    sx={{
                      opacity: "0.75",
                      marginBottom: "30px",
                      fontSize: "18px",
                    }}
                  >
                    At SYM International, we ensure your e-commerce operations
                    run smoothly by managing every aspect of fulfillment, from
                    inventory management to order processing and last-mile
                    delivery. With us as your partner, your customers enjoy
                    fast, accurate, and reliable deliveries every time.
                  </Typography>
                  <Button
                    onClick={toggleItems1}
                    variant="contained"
                    sx={{
                      alignSelf: "flex-start",
                      borderRadius: "35px",
                      padding: "10px 20px",
                      backgroundColor: "#ffffff",
                      color: "#391400",
                    }}
                  >
                    {showItems1 ? "Read Less" : "Read More"}
                  </Button>
                </td>
              </tr>
              {showItems1 && (
                <tr>
                  <td colSpan="1"></td>
                  <td colSpan="2">
                    <ul style={{ textAlign: "left" }}>
                      <li>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: "600" }}
                        >
                          Receiving
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#391400"
                          textAlign="justify"
                          // paddingTop="30px"
                          sx={{
                            opacity: "0.75",
                            marginBottom: "20px",
                            fontSize: "18px",
                          }}
                        >
                          Accurate handling and verification of incoming
                          inventory.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: "600" }}
                        >
                          Storing
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#391400"
                          textAlign="justify"
                          // paddingTop="30px"
                          sx={{
                            opacity: "0.75",
                            marginBottom: "20px",
                            fontSize: "18px",
                          }}
                        >
                          Secure, organized storage solutions tailored to your
                          products.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: "600" }}
                        >
                          Repacking
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#391400"
                          textAlign="justify"
                          // paddingTop="30px"
                          sx={{
                            opacity: "0.75",
                            marginBottom: "20px",
                            fontSize: "18px",
                          }}
                        >
                          Customized packaging to meet branding or shipping
                          requirements.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: "600" }}
                        >
                          Pick-Packing
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#391400"
                          textAlign="justify"
                          // paddingTop="30px"
                          sx={{
                            opacity: "0.75",
                            marginBottom: "20px",
                            fontSize: "18px",
                          }}
                        >
                          Efficient order picking and packing to ensure timely
                          delivery.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: "600" }}
                        >
                          Labelling
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#391400"
                          textAlign="justify"
                          // paddingTop="30px"
                          sx={{
                            opacity: "0.75",
                            marginBottom: "20px",
                            fontSize: "18px",
                          }}
                        >
                          Proper product labeling for compliance and easy
                          identification.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: "600" }}
                        >
                          Shipping
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#391400"
                          textAlign="justify"
                          // paddingTop="30px"
                          sx={{
                            opacity: "0.75",
                            marginBottom: "20px",
                            fontSize: "18px",
                          }}
                        >
                          Reliable delivery to your customers via trusted
                          carriers.
                          <br></br>
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: "600" }}
                        >
                          Returns
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#391400"
                          textAlign="justify"
                          // paddingTop="30px"
                          sx={{
                            opacity: "0.75",
                            marginBottom: "20px",
                            fontSize: "18px",
                          }}
                        >
                          Streamlined returns management for hassle-free
                          processing.
                        </Typography>
                      </li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr id="Freight Forwarding">
                <td>
                <Typography
                    variant="h3"
                    fontWeight="900"
                    fontSize="43px"
                    textAlign="left"
                    color="#391400"
                    sx={{ marginBottom: "20px" }}
                  >
                    Freight Forwarding
                  </Typography>

                  <Typography
                    variant="body1"
                    color="#391400"
                    textAlign="justify"
                    paddingTop="30px"
                    sx={{
                      opacity: "0.75",
                      marginBottom: "30px",
                      fontSize: "18px",
                    }}
                  >
                    Our freight forwarding services provide seamless
                    transportation solutions for your goods, whether by air,
                    sea, or land. SYM International handles the complexities of
                    logistics with precision, ensuring your shipments reach
                    their destination on time and within budget.
                  </Typography>
                  <Button
                    onClick={toggleItems2}
                    variant="contained"
                    sx={{
                      alignSelf: "flex-start",
                      borderRadius: "35px",
                      padding: "10px 20px",
                      backgroundColor: "#ffffff",
                      color: "#391400",
                    }}
                  >
                    {showItems2 ? "Read Less" : "Read More"}
                  </Button>
                </td>
                <td style={{ verticalAlign: "top", paddingTop: "50px" }}>
                <img src={ffImg} alt="freight forwarding image" style={{maxHeight:'380px'}}></img>
                </td>
              </tr>
              {showItems2 && (
                <tr>
                  <td colSpan="1">
                    <ul>
                      <li>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: "600", textAlign:'left'}}
                        >
                          Air Freight
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#391400"
                          textAlign="justify"
                          // paddingTop="30px"
                          sx={{
                            opacity: "0.75",
                            marginBottom: "20px",
                            fontSize: "18px",
                          }}
                        >
                          Fast and reliable transportation for urgent shipments.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: "600", textAlign:'left'}}
                        >
                          Ocean Freight
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#391400"
                          textAlign="justify"
                          // paddingTop="30px"
                          sx={{
                            opacity: "0.75",
                            marginBottom: "20px",
                            fontSize: "18px",
                          }}
                        >
                          Cost-effective shipping for large volumes across the
                          globe.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: "600", textAlign:'left'}}
                        >
                          Land Freight
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#391400"
                          textAlign="justify"
                          // paddingTop="30px"
                          sx={{
                            opacity: "0.75",
                            marginBottom: "20px",
                            fontSize: "18px",
                          }}
                        >
                          Flexible and efficient trucking solutions for domestic
                          and cross-border logistics.
                        </Typography>
                      </li>
                    </ul>
                  </td>
                  <td colSpan="1"></td>
                </tr>
              )}
              <tr id="Custom Clearance">
                <td style={{ verticalAlign: "top", paddingTop: "50px" }}>
                <img src={customClearance} alt="custom clearance image" style={{maxHeight:'310px'}}></img>
                </td>
                <td>
                <Typography
                    variant="h3"
                    fontWeight="900"
                    fontSize="43px"
                    textAlign="left"
                    color="#391400"
                    sx={{ marginBottom: "20px" }}
                  >
                    Custom <br></br>Clearance
                  </Typography>
                  <Typography
                    variant="body1"
                    color="#391400"
                    textAlign="justify"
                    paddingTop="30px"
                    sx={{
                      opacity: "0.75",
                      marginBottom: "30px",
                      fontSize: "18px",
                    }}
                  >
                    Navigating customs regulations can be daunting, but SYM
                    International makes it effortless. Our experienced team
                    ensures fast and hassle-free clearance of your goods,
                    minimizing delays and ensuring compliance with all
                    international trade requirements.
                  </Typography>
                  <Button
                    onClick={toggleItems3}
                    variant="contained"
                    sx={{
                      alignSelf: "flex-start",
                      borderRadius: "35px",
                      padding: "10px 20px",
                      backgroundColor: "#ffffff",
                      color: "#391400",
                    }}
                  >
                    {showItems3 ? "Read Less" : "Read More"}
                  </Button>
                </td>
              </tr>
              {showItems3 && (
                <tr>
                  <td colSpan="1"></td>
                  <td colSpan="2">
                    <ul>
                      <li>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: "600", textAlign:'left'}}
                        >
                          Air Clearance
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#391400"
                          textAlign="justify"
                          // paddingTop="30px"
                          sx={{
                            opacity: "0.75",
                            marginBottom: "20px",
                            fontSize: "18px",
                          }}
                        >
                          Rapid processing of air cargo through customs compliance.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: "600", textAlign:'left'}}
                        >
                          Ocean Clearance
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#391400"
                          textAlign="justify"
                          // paddingTop="30px"
                          sx={{
                            opacity: "0.75",
                            marginBottom: "20px",
                            fontSize: "18px",
                          }}
                        >
                          Hassle-free clearance for sea shipments, ensuring smooth imports and exports.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: "600", textAlign:'left'}}
                        >
                          Land Clearance
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#391400"
                          textAlign="justify"
                          // paddingTop="30px"
                          sx={{
                            opacity: "0.75",
                            marginBottom: "20px",
                            fontSize: "18px",
                          }}
                        >
                          Seamless clearance for goods transported via road or rail.
                        </Typography>
                      </li>
                    </ul>
                  </td>
                </tr>
              )}
              <tr id="Warehousing">
                <td>
                <Typography
                    variant="h3"
                    fontWeight="900"
                    fontSize="43px"
                    textAlign="left"
                    color="#391400"
                    letterSpacing="-1px"
                    sx={{ marginBottom: "20px" }}
                  >
                    Warehousing
                  </Typography>
                  <Typography
                    variant="body1"
                    color="#391400"
                    textAlign="justify"
                    paddingTop="30px"
                    sx={{
                      opacity: "0.75",
                      marginBottom: "30px",
                      fontSize: "18px",
                    }}
                  >
                    SYM International offers secure, scalable, and strategically
                    located warehousing solutions to meet your business needs.
                    From storage to inventory management, our facilities are
                    designed to optimize your supply chain and keep your
                    operations running efficiently.
                  </Typography>
                  <Button
                    onClick={toggleItems4}
                    variant="contained"
                    sx={{
                      alignSelf: "flex-start",
                      borderRadius: "35px",
                      padding: "10px 20px",
                      backgroundColor: "#ffffff",
                      color: "#391400",
                    }}
                  >
                    {showItems4 ? "Read Less" : "Read More"}
                  </Button>
                </td>
                <td style={{ verticalAlign: "top", paddingTop: "50px" }}>
                <img src={warehousingImg} alt="warehousing image" style={{maxHeight:'250px'}}></img>
                </td>
              </tr>
              {showItems4 && (
                <tr>
                  <td colSpan="1">
                    <ul>
                      <li>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: "600", textAlign:'left'}}
                        >
                          Dangerous Goods
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#391400"
                          textAlign="justify"
                          // paddingTop="30px"
                          sx={{
                            opacity: "0.75",
                            marginBottom: "20px",
                            fontSize: "18px",
                          }}
                        >
                          Specialized storage for hazardous materials with strict compliance.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: "600", textAlign:'left'}}
                        >
                          Non-Dangerous Goods
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#391400"
                          textAlign="justify"
                          // paddingTop="30px"
                          sx={{
                            opacity: "0.75",
                            marginBottom: "20px",
                            fontSize: "18px",
                          }}
                        >
                          Secure and scalable warehousing for standard products.
                        </Typography>
                      </li>
                      <li>
                        <Typography
                          style={{ fontSize: "20px", fontWeight: "600", textAlign:'left'}}
                        >
                          Perishable Goods
                        </Typography>
                        <Typography
                          variant="body1"
                          color="#391400"
                          textAlign="justify"
                          // paddingTop="30px"
                          sx={{
                            opacity: "0.75",
                            marginBottom: "20px",
                            fontSize: "18px",
                          }}
                        >
                          Temperature-controlled facilities for safe storage of perishable items.
                        </Typography>
                      </li>
                    </ul>
                  </td>
                  <td colSpan="1"></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Box>

      {/* Testimonials Section */}
      <Box
        sx={{ backgroundColor: "#001d3d", color: "#FFFFFF", padding: "60px" }}
      >
        <TestimonialsSlider />
        {/* <Testimonials /> */}
      </Box>

      {/* FAQs Section */}
      <Box sx={{ padding: "60px 100px" }}>
        <FAQs />
      </Box>

      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default ServicesPage;
