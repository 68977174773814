import React, { useState } from "react";
import Slider from "react-slick";
import { Box, Typography, Avatar } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/css/TestimonialsSlider.css";

const testimonials = [
  {
    rating: 5,
    text: "Reliable, efficient, and always ahead! Working with this 4PL partner has transformed our logistics. They handle everything seamlessly, from inventory to last-mile delivery. Truly a game-changer!",
    name: "Alan Martí",
    position: "Supply Chain Manager, Retail Industry",
    avatar: "https://via.placeholder.com/50",
  },
  {
    rating: 5,
    text: "A trustworthy partnership! As our business expanded, they stepped in with customized solutions. Their support has been consistent and professional.",
    name: "Richard Roe",
    position: "Operations Leader",
    avatar: "https://via.placeholder.com/50",
  },
  {
    rating: 5,
    text: "A trustworthy partnership! As our business expanded, they stepped in with customized solutions. Their support has been consistent and professional.",
    name: "Charles Joyce",
    position: "Operations Leader",
    avatar: "https://via.placeholder.com/50",
  },
  {
    rating: 5,
    text: "A trustworthy partnership! As our business expanded, they stepped in with customized solutions. Their support has been consistent and professional.",
    name: "Logan Huntzberger",
    position: "Operations Leader",
    avatar: "https://via.placeholder.com/50",
  },
];

const TestimonialsSlider = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0",
    swipeToSlide: true,
    touchMove: true,
    afterChange: (current) => setActiveSlide(current),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "30px",
        },
      },
    ],
  };

  return (
    <Box sx={{ backgroundColor: "#001d3d", py: 6, color: "white" }}>
      <Typography
        variant="h6"
        align="center"
        fontSize="16px"
        sx={{ color: "#e57373", textTransform: "uppercase", mb: 1 }}
      >
        Testimonials
      </Typography>
      <Typography
        variant="h3"
        align="center"
        sx={{ fontWeight: "bold", mb: 4, paddingBottom: "30px" }}
      >
        What our<br></br> customers say
      </Typography>

      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <Box
            key={index}
            className={`testimonial-card ${
              index === activeSlide ? "active" : ""
            }`}
            sx={{
              backgroundColor: "white",
              color: "#333",
              maxWidth: "100%",
              mx: "auto",
              p: 4,
              borderRadius: 4,
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
              textAlign: "center",
              opacity: index === activeSlide ? 1 : 0.5,
              transform: index === activeSlide ? "scale(1)" : "scale(0.8)",
              zIndex: index === activeSlide ? 2 : 1,
              transition: "all 0.2s ease",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              {[...Array(testimonial.rating)].map((_, i) => (
                <StarIcon key={i} sx={{ color: "#FFD700", mr: 0.5 }} />
              ))}
            </Box>

            <Typography
              variant="h7"
              fontStyle="italic"
              mb={2}
              sx={{ lineHeight: 1.6 }}
            >
              {testimonial.text}
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Avatar
                src={testimonial.avatar}
                alt={testimonial.name}
                sx={{ mr: 2 }}
              />
              <Box>
                <Typography variant="h6" fontWeight="bold">
                  {testimonial.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {testimonial.position}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default TestimonialsSlider;





// import React from "react";
// import Slider from "react-slick";
// import { Box, Typography, Avatar } from "@mui/material";
// import StarIcon from "@mui/icons-material/Star";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "../assets/css/TestimonialsSlider.css"


// const testimonials = [
//   {
//     rating: 5,
//     text: `"Reliable, efficient, and always ahead!" Working with this 4PL partner has transformed our logistics. They handle everything seamlessly, from inventory to last-mile delivery. Truly a game-changer!`,
//     name: "Alan Martí",
//     position: "Supply Chain Manager, Retail Industry",
//     avatar: "https://via.placeholder.com/50", // Replace with the actual image URL
//   },
//   {
//     rating: 5,
//     text: `"A trustworthy partnership!" As our business expanded, they stepped in with customized solutions. Their support has been consistent and professional.`,
//     name: "Richard Roe",
//     position: "Operations Leader",
//     avatar: "https://via.placeholder.com/50", // Replace with the actual image URL
//   },
//   {
//     rating: 5,
//     text: `"A trustworthy partnership!" As our business expanded, they stepped in with customized solutions. Their support has been consistent and professional.`,
//     name: "Charles Joyce",
//     position: "Operations Leader",
//     avatar: "https://via.placeholder.com/50", // Replace with the actual image URL
//   },
//   {
//     rating: 5,
//     text: `"A trustworthy partnership!" As our business expanded, they stepped in with customized solutions. Their support has been consistent and professional.`,
//     name: "Logan Huntzberger",
//     position: "Operations Leader",
//     avatar: "https://via.placeholder.com/50", // Replace with the actual image URL
//   },
// ];


// const TestimonialsSlider = () => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     centerMode: true,
//     centerPadding: "0",
//     swipeToSlide: true,
//     touchMove: true,
//     responsive: [
//       {
//         breakpoint: 768, // For smaller screens
//         settings: {
//           slidesToShow: 1,
//           centerPadding: "30px",
//         },
//       },
//     ],
//   };

//   return (
//     <Box sx={{ backgroundColor: "#001d3d", py: 6, color: "white" }}>
//       <Typography
//         variant="h6"
//         align="center"
//         fontSize="16px"
//         sx={{ color: "#e57373", textTransform: "uppercase", mb: 1 }}
//       >
//         Testimonials
//       </Typography>
//       <Typography
//         variant="h3"
//         align="center"
//         sx={{ fontWeight: "bold", mb: 4, paddingBottom: "30px" }}
//       >
//         What our<br></br> customers say
//       </Typography>

//       <Slider {...settings}>
//         {testimonials.map((testimonial, index) => (
//           <Box
//             key={index}
//             sx={{
//               backgroundColor: "white",
//               color: "#333",
//               maxWidth: "100%",
//               mx: "auto",
//               p: 4,
//               borderRadius: 4,
//               boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
//               textAlign: "center",
//               opacity: index === 1 ? 0.5 : 1, // Center card full opacity, others dimmed
//               transform: index === 1 ? "scale(0.8)" : "scale(1)", // Emphasize the center card
//               transition: "all 0.3s ease",
//             }}
//           >
//             <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
//               {[...Array(testimonial.rating)].map((_, i) => (
//                 <StarIcon key={i} sx={{ color: "#FFD700", mr: 0.5 }} />
//               ))}
//             </Box>

//             <Typography
//               variant="h7"
//               fontStyle="italic"
//               mb={2}
//               sx={{ lineHeight: 1.6 }}
//             >
//               {testimonial.text}
//             </Typography>

//             <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
//               <Avatar
//                 src={testimonial.avatar}
//                 alt={testimonial.name}
//                 sx={{ mr: 2 }}
//               />
//               <Box>
//                 <Typography variant="h6" fontWeight="bold">
//                   {testimonial.name}
//                 </Typography>
//                 <Typography variant="body2" color="textSecondary">
//                   {testimonial.position}
//                 </Typography>
//               </Box>
//             </Box>
//           </Box>
//         ))}
//       </Slider>
//     </Box>
//   );
// };

// export default TestimonialsSlider;