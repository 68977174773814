import React from "react";
import "../assets/css/aboutus.css";
import { Box, Typography, Button } from "@mui/material";
import { Col, Row } from "react-grid-system";
import aboutusimg from "../assets/imgs/aboutus.png";
import ourbeliefimg from "../assets/imgs/ourbelief.jpg";
import invertedcomma from "../assets/imgs/invertedcomma.png";

const AboutUs = () => {
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "1960px",
        display: "flex",
        position: "relative",
        justifyContent: "center",
        backgroundColor: "#D2E8FF",
        flexDirection: "column",
        alignItems: "center",
        padding: "2rem 2rem",
        overflowX: "hidden",
        boxSizing: "border-box",
      }}
    >
      <div style={{ flex: 1 }}>
        <Box
          sx={{
            maxWidth: "1200px",
            margin: "0 auto",
            width: "35%",
            display: "flex",
            position: "absolute",
            textAlign: "center",
            backgroundColor: "#D2E8FF",
            mb: 4,
            top: "0px",
            left: "150px",
            paddingTop: "0px",
          }}
        >
          <img
            src={aboutusimg}
            alt="about us image"
            style={{ height: "auto", maxWidth: "550px", objectFit: "contain" }}
          />
        </Box>
      </div>

      <div style={{ flex: 1 }}>
        <Box
          sx={{
            maxWidth: "1200px", // Center content within a max width
            margin: "0 auto", // Centers the container
            width: "50%",
            display: "flex",
            alignItems: "center",
            position: "absolute",
            textAlign: "center",
            padding: "15px",
            mb: 4,
            top: "130px",
            left: "550px",
            // paddingTop:'120px',
          }}
        >
          <Typography
            variant="h6"
            style={{
              position: "absolute",
              top: "5px",
              left: "250px",
              fontSize: "16px",
              fontWeight: "400",
              pb:'10px'
            }}
            className="subtitle"
            gutterBottom
          >
            ABOUT US<br></br>
          </Typography>
          <Typography
            variant="h3"
            style={{ position: "absolute", top: "30px", left: "250px" }}
            fontWeight="1000"
            color="#391400"
            letterSpacing="-2px"
          >
            Who we are
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ position: "absolute", top: "100px", left: "250px", fontStyle: "italic", fontSize: "18px"}}
            color="textSecondary"
            paragraph
          >
            "One partnership, endless logistics possibilities"
          </Typography>
          <Typography
            variant="content"
            style={{ position: "absolute", top: "140px", left: "250px", textAlign:'justify', lineHeight: "1.4"}}
            color="textSecondary"
            paragraph
          >
            At SYM International, we pride ourselves on being your trusted 4PL
            partner, offering end-to-end logistics solutions that simplify and
            enhance your supply chain. From e-commerce fulfillment and freight
            forwarding to customs clearance and warehousing, our expertise
            ensures seamless operations tailored to your business needs. With a
            commitment to innovation, transparency, and efficiency, we provide
            scalable solutions that empower your growth and success. SYM International is
            here to make logistics effortless for you.
          </Typography>
          {/* <Button
            variant="contained"
            className="faqs-button"
            style={{
              backgroundColor: "#ffffff",
              color: "#333333",
              fontWeight: "bold",
              padding: "10px 20px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              textTransform: "uppercase",
              position: "absolute",
              top: "420px",
              left: "250px",
            }}
          >
            About Us
          </Button> */}
        </Box>
      </div>

      {/* Experience Counter Section */}
      <div style={{ flex: 1 }}>
        <Box
          sx={{
            maxWidth: "1200px", // Center content within a max width
            margin: "0 auto", // Centers the container
            width: "100%",
            textAlign: "center",
            padding: "2rem 0",
            display: "flex",
            position: "absolute",
            top: "690px",
            left: "170px",
          }}
        >
          <Row
            container
            spacing={2}
            justifyContent="center"
            style={{ color: "#391400" }}
          >
            <Col item xs={12} sm={4}>
              <Box
                sx={{
                  backgroundColor: "#f9f9f9",
                  padding: "2rem",
                  borderRadius: "8px",
                  display: "flex",
                  position: "absolute",
                  top: "100px",
                  left: "70px",
                  height: "120px",
                  width: "230px",
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight="1000"
                  style={{ position: "absolute", top: "35px" }}
                >
                  25+
                </Typography>
                <Typography
                  color="textSecondary"
                  style={{ position: "absolute", top: "85px" }}
                >
                  Years of experience
                </Typography>
              </Box>
            </Col>
            <Col item xs={12} sm={4}>
              <Box
                sx={{
                  backgroundColor: "#BDDEFF",
                  padding: "2rem",
                  borderRadius: "8px",
                  display: "flex",
                  position: "absolute",
                  top: "100px",
                  left: "360px",
                  height: "120px",
                  width: "230px",
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight="1000"
                  style={{ position: "absolute", top: "35px" }}
                >
                  1000+
                </Typography>
                <Typography
                  color="textSecondary"
                  style={{ position: "absolute", top: "85px" }}
                >
                  Satisfied Customers
                </Typography>
              </Box>
            </Col>
            <Col item xs={12} sm={4}>
              <Box
                sx={{
                  backgroundColor: "#f9f9f9",
                  padding: "2rem",
                  borderRadius: "8px",
                  display: "flex",
                  position: "absolute",
                  top: "100px",
                  left: "650px",
                  height: "120px",
                  width: "220px",
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight="1000"
                  style={{ position: "absolute", top: "35px" }}
                >
                  5000+
                </Typography>
                <Typography
                  color="textSecondary"
                  style={{ position: "absolute", top: "85px" }}
                >
                  Projects completed
                </Typography>
              </Box>
            </Col>
          </Row>
        </Box>
      </div>

      {/* Belief Section */}
      <Box style={{ borderTop: "1.5px solid #e57373" }}>
        <div style={{ flex: 1 }}>
          <Box
            sx={{
              maxWidth: "1200px", // Center content within a max width
              margin: "0 auto", // Centers the container
              display: "flex",
              position: "absolute",
              textAlign: "center",
              borderRadius: "5px",
              padding: "25px",
              mb: 4,
              top: "1330px",
              left: "150px",
            }}
          >
            <img
              src={ourbeliefimg}
              alt="our belief image"
              style={{
                height: "auto",
                maxWidth: "550px",
                objectFit: "contain",
              }}
            />
          </Box>
        </div>
        <div style={{ flex: 1 }}>
          <Box
            sx={{
              width: "50%",
              maxWidth: "430px",
              display: "flex",
              position: "absolute",
              textAlign: "justify",
              top: "1390px",
              left: "790px",
            }}
          >
            <img
              src={invertedcomma}
              alt="inverted comma"
              style={{
                height: "auto",
                maxWidth: "110px",
                objectFit: "contain",
                position: "absolute",
                left: "330px",
              }}
            />
            <Typography
              variant="h6"
              style={{
                position: "absolute",
                top: "0px",
                fontSize: "16px",
                fontWeight: "400",
              }}
              className="subtitle"
              gutterBottom
            >
              OUR BELIEF
            </Typography>
            <Typography
              variant="h4"
              style={{
                position: "absolute",
                top: "55px",
                fontSize: "40px",
                fontWeight: "750",
                color: "#002A6C",
              }}
            >
              Driving Efficiency,<br></br>Reducing Costs,<br></br>Building
              Value.
            </Typography>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default AboutUs;