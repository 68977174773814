import React, { useEffect, useState, useRef } from "react";
import { Box, Typography } from "@mui/material";
import Navbar from "../../components/Navbar";
import stars from "../../assets/imgs/stars.png";

const Hero = ({ heading, subtitle, date, author }) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const aboutUsRef = useRef(null);

  const scrollToAboutUs = () => {
    if (aboutUsRef.current) {
      aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#023079",
        color: "#ffffff",
        position: "relative",
      }}
    >
      <div
        className={`navbar ${isSticky ? "sticky" : ""}`}
        style={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "#023079",
        }}
      >
        <Navbar activePage={heading} onScrollToAboutUs={scrollToAboutUs} />
      </div>

      {subtitle ? (
        // Render heading, stars, subtitle, date, and author
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "117px 65px 15px 100px",
            textAlign: "center",
            height: "400px",
            position: "relative",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "100px",
          }}
        >
          <img
            src={stars}
            alt="Stars Background"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "150px",
              zIndex: 0,
            }}
          />
          {/* Heading */}
          <Typography
            variant="h2"
            sx={{
              fontWeight: "bold",
              fontSize: "55px",
              position: "relative",
              zIndex: 1,
              paddingLeft: "40px",
              paddingRight: "40px",
              letterSpacing: 1.5,
              marginBottom: "15px",
            }}
          >
            {heading}
          </Typography>

          {/* Subtitle */}
          <Typography
            variant="h6"
            sx={{
              fontSize: "20px",
              position: "relative",
              zIndex: 1,
              color: "#f6f6f6",
              marginBottom: "20px",
            }}
          >
            {subtitle}
          </Typography>

          {/* Author and Date */}
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              fontStyle: "italic",
              color: "#ddd",
              position: "relative",
              zIndex: 1,
            }}
          >
            In Stories by {author} | Date {date}
          </Typography>
        </Box>
      ) : (
        // Render only heading and stars
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "117px 65px 15px 100px",
            textAlign: "center",
            height: "300px",
            position: "relative",
          }}
        >
          <img
            src={stars}
            alt="Stars Background"
            style={{
              position: "absolute",
              top: "55%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "150px",
              zIndex: 0,
            }}
          />

          <Typography
            variant="h2"
            sx={{
              fontWeight: "bold",
              fontSize: "58px",
              position: "relative",
              zIndex: 1,
              letterSpacing: 1.5,
            }}
          >
            {heading}
          </Typography>
        </Box>
      )}

    </div>
  );
};

export default Hero;
