import React from "react";
import { Routes, Route } from "react-router-dom";
import ServicesPage from "./pages/ServicesPage";
import Home from "./pages/Home";
import BlogsPage from "./pages/BlogsPage";
import ContactUsPage from "./pages/ContactUsPage";
import AboutUs from "./components/AboutUs";
import SingleBlogPage from "./pages/SingleBlogPage";
import AboutUsPage from "./pages/AboutUsPage";

const App = () => {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/aboutus" element={<AboutUs />} /> */}
        <Route path="/aboutus" element={<AboutUsPage />} /> 
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/contactus" element={<ContactUsPage />} />
        <Route exact path="/blogs/:blogid" element={<SingleBlogPage />} />
      </Routes>
  );
};

export default App;
