import React, { useState } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import "../assets/css/servicespage.css";
import Hero from "../components/hero/heroComponent";
import TestimonialsSlider from "../components/TestimonialsSlider";
import FAQs from "../components/FAQs";
import Footer from "../components/Footer";
import AboutUs from "../components/AboutUs";
import aboutusimg from "../assets/imgs/aboutus.png";
import cartoonLeft from "../assets/imgs/cartoon.png";

const AboutUsPage = () => {
  return (
    <div className="container-fluid" style={{ backgroundColor: "#E3F2FD" }}>
      {/* Hero Section */}
      <Hero heading="About Us" />
      {/* About Us Section */}
      {/* <AboutUs /> */}
      <Box
        sx={{
          width: "100%",
          minHeight: "880px",
          display: "flex",
          position: "relative",
          justifyContent: "center",
          backgroundColor: "#D2E8FF",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem 2rem",
          overflowX: "hidden",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            maxWidth: "1200px",
            margin: "0 auto",
            width: "100%",
            display: "flex",
            position: "absolute",
            textAlign: "left",
            backgroundColor: "#D2E8FF",
            mb: 4,
            top: "0px",
            left: "10px",
            paddingTop: "0px",
          }}
        >
          <div style={{ flex: 1 }}>
            <Typography
              variant="h6"
              style={{
                position: "absolute",
                top: "70px",
                left: "150px",
                fontSize: "16px",
                fontWeight: "400",
                pb: "10px",
              }}
              className="subtitle"
              gutterBottom
            >
              ABOUT US<br></br>
            </Typography>
            <Typography
              variant="h3"
              style={{ position: "absolute", top: "100px", left: "150px" }}
              fontWeight="1000"
              color="#391400"
              letterSpacing="-2px"
            >
              Who we are
            </Typography>
          </div>
          <img
            src={aboutusimg}
            alt="about us image"
            style={{
              height: "auto",
              maxWidth: "600px",
              objectFit: "contain",
              position: "absolute",
              top: "120px",
              left: "70px",
            }}
          />
        </Box>

        <div style={{ flex: 1 }}>
          <Box
            sx={{
              maxWidth: "1200px", 
              margin: "0 auto", 
              width: "50%",
              display: "flex",
              alignItems: "center",
              position: "absolute",
              textAlign: "center",
              padding: "15px",
              mb: 4,
              top: "30px",
              left: "500px",
            }}
          >
            <Typography
              variant="subtitle1"
              style={{
                position: "absolute",
                top: "100px",
                left: "250px",
                fontStyle: "italic",
                fontSize: "22px",
                textAlign:"center",
              }}
              color="textSecondary"
              paragraph
            >
              "One partnership, endless logistics possibilities"
            </Typography>
            <Typography
              variant="content"
              style={{
                position: "absolute",
                top: "200px",
                fontSize: "18px",
                left: "250px",
                textAlign: "justify",
                lineHeight: "1.4",
              }}
              color="textSecondary"
              paragraph
            >
              At SYM International, we pride ourselves on being your trusted 4PL
              partner, offering end-to-end logistics solutions that simplify and
              enhance your supply chain. From e-commerce fulfillment and freight
              forwarding to customs clearance and warehousing, our expertise
              ensures seamless operations tailored to your business needs. With
              a commitment to innovation, transparency, and efficiency, we
              provide scalable solutions that empower your growth and success.
              SYM International is here to make logistics effortless for you.
            </Typography>
          </Box>
          <img
            src={cartoonLeft}
            alt="cartoon image"
            style={{ height: "auto", maxWidth: "350px", objectFit: "contain", position: "absolute", top: "270px", left: "200px" }}
          />
        </div>
      </Box>
      {/* Testimonials Section */}
      <Box
        sx={{ backgroundColor: "#001d3d", color: "#FFFFFF", padding: "60px" }}
      >
        <TestimonialsSlider />
        {/* <Testimonials /> */}
      </Box>

      {/* FAQs Section */}
      <Box sx={{ padding: "60px 100px" }}>
        <FAQs />
      </Box>
      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default AboutUsPage;
