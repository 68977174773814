import React from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Card,
  CardContent,
  Avatar,
  IconButton,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";

const SeeAllTestimonials = ({ open, onClose, testimonials }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent sx={{ position: "relative", padding: 4, backgroundColor: "#E3F2FD" }}>
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 10, right: 10, color: "grey.600" }}
        >
          <CloseIcon />
        </IconButton>

        {/* Title */}
        <Typography variant="h4" sx={{ textAlign: "center", fontWeight: "800", mb: 3, color:'#391400' }}>
          All Testimonials
        </Typography>

        {/* List of Testimonials */}
        <Grid container spacing={2}>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card
                sx={{
                  boxShadow: 2,
                  borderRadius: 2,
                  padding: 2,
                }}
              >
                <CardContent>
                  {/* Rating */}
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1, color:'#391400' }}>
                    {Array(testimonial.rating)
                      .fill()
                      .map((_, i) => (
                        <StarIcon key={i} sx={{ color: "#FFD700", fontSize: 20 }} />
                      ))}
                  </Box>

                  {/* Quote */}
                  <Typography variant="h6" fontWeight="bold" sx={{ mb: 1, color: "#3C1D00" }}>
                    {testimonial.quote}
                  </Typography>

                  {/* Description */}
                  <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {testimonial.description}
                  </Typography>

                  {/* User Info */}
                  <Box display="flex" alignItems="center">
                    <Avatar src={testimonial.avatar} alt={testimonial.name} sx={{ mr: 2 }} />
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#3C1D00" }}>
                        {testimonial.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {testimonial.title}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SeeAllTestimonials;
