import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import Navbar from "../components/Navbar";
import robotImage from "../assets/imgs/homepage-header.png";
import { Link } from 'react-router-dom';

const Hero = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const aboutUsRef = useRef(null);

  const scrollToAboutUs = () => {
    if (aboutUsRef.current) {
      aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div style={{ backgroundColor: "#023079", color: "#ffffff" }}>
      <div
        className={`navbar ${isSticky ? "sticky" : ""}`}
        style={{
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "#023079",
        }}
      >
        <Navbar onScrollToAboutUs={scrollToAboutUs} />
      </div>

      <div
        style={{
          display: "flex",
          position:"relative",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "117px 65px 15px 100px",
        }}
      >
        <div style={{ flex: 1 }}>
          <Box textAlign="left" py={8}>
            <Typography
              variant="body2"
              style={{ position:"absolute", top: "220px", left: "110px", textTransform: "uppercase", color: "#EF6D58", fontSize: "14px", letterSpacing: "1px" }}
            >
              One partnership. Endless logistics possibilities.
            </Typography>

            <Typography
              variant="h2"
              style={{ position:"absolute", top: "250px", left: "110px", fontWeight: "bold", fontSize: "60px", marginTop: "16px" }}
            >
              Your Next Door <br />4PL Partner
            </Typography>

            <Typography
              variant="body1"
              style={{
                position:"absolute", 
                top: "420px", 
                left: "110px",
                fontSize: "16px",
                lineHeight: "1.5",
                marginTop: "16px",
                maxWidth: "27%",
                opacity: "0.5",
              }}
            >
              With 25 years of experience, we ensure to simplify and enhance every step of your supply chain, offering tailored, transparent solutions that keep you ahead.
            </Typography>

            <div style={{ marginTop: "24px" }}>
            <Link to="/services" style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                style={{
                  position:"absolute", 
                  top: "565px", 
                  left: "110px",
                  backgroundColor: "#EF6D58",
                  color: "#ffffff",
                  padding: "10px 20px",
                  fontSize: "14px",
                  textTransform: "capitalize",
                  marginRight: "10px",
                }}
              >
                What we offer
              </Button>
              </Link>
              <Link to="/contactus" style={{ textDecoration: 'none' }}>
              <Button

                variant="outlined"
                style={{
                    position:"absolute",
                    top: "565px", 
                  left: "270px",
                  borderColor: "#EF6D58",
                  color: "#ffffff",
                  padding: "10px 20px",
                  fontSize: "14px",
                  textTransform: "capitalize",
                }}
              >
                Contact us
              </Button>
              </Link>
            </div>
          </Box>
        </div>

        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          <img
            src={robotImage}
            alt="Robot illustration"
            style={{ height: "auto", maxWidth: "900px", objectFit: "contain" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;




// import React from "react";
// import { Box, Typography, Button } from "@mui/material";
// import robotImage from "../assets/imgs/homepage-header.png";
// import Navbar from '../components/Navbar';


// const Hero = () => (

//   <div
//     style={{
//       display: "flex",
//       alignItems: "center",
//       backgroundColor: "#023079",
//       color: "#ffffff",
//       padding: "97px 65px 15px 70px",
//     }}
//   >
//         <div>
//         <Navbar />
//     </div>
//     <div className="textHolder" style={{ flex: 3 }}>
//       <Box
//         textAlign="left"
//         py={8}
//         // style={{ backgroundImage:'robotImage'}}
//       >
//         <Typography
//           variant="h7"
//           style={{ textTransform: "uppercase", color: "#EF6D58" }}
//         >
//           One partnership. Endless logistics possibilities.
//         </Typography>
//         <div style={{ display: "flex" }}>
//           <div style={{ flex: 1 }}>
//             <Typography
//               variant="h2"
//               mt={2}
//               fontWeight={700}
//               style={{ maxWidth: "100%" }}
//             >
//               Your Next Door 4PL Partner
//             </Typography>
//             <Typography
//               variant="body1"
//               mt={2}
//               style={{
//                 fontSize: "16px",
//                 lineHeight: "1.5",
//                 maxWidth: "97%",
//                 opacity: "65%",
//               }}
//             >
//               With 25 years of experience, we ensure to simplify and enhance
//               every step of your supply chain, offering tailored, transparent
//               solutions that keep you ahead.
//             </Typography>
//             <Button
//               variant="contained"
//               style={{
//                 marginTop: "20px",
//                 backgroundColor: "#EF6D58",
//                 color: "#ffffff",
//                 padding: "8px 12px",
//                 fontSize: "13px",
//                 textTransform: "capitalize",
//                 marginRight: "10px",
//               }}
//             >
//               What we offer
//             </Button>
//             <Button
//               variant="outlined"
//               style={{
//                 marginTop: "20px",
//                 borderColor: "#EF6D58",
//                 color: "#ffffff",
//                 padding: "8px 12px",
//                 fontSize: "13px",
//                 textTransform: "capitalize",
//               }}
//             >
//               Contact us
//             </Button>
//           </div>
//           <div style={{ flex: 1 }}>
//             <img
//               src={robotImage}
//               alt="header-image"
//               style={{ height: "auto", maxWidth: "800px" }}
//             />
//           </div>
//         </div>
//       </Box>
//     </div>
//   </div>
// );

// export default Hero;

// // import React from "react";
// // import { Box, Typography, Button } from "@mui/material";
// // import robotImage from "../assets/imgs/homepage-header.png";

// // const Hero = () => (
// //   <div
// //     style={{
// //       backgroundImage: "linear-gradient(180deg, #023079, #002A6C)",
// //       color: "#ffffff",
// //       padding: "50px 65px",
// //       display: "flex", // Set flexbox on the main container
// //       alignItems: "center", // Center items vertically
// //       justifyContent: "space-between", // Space out textHolder and imgHolder
// //     }}
// //   >
// //     <div className="textHolder">
// //       <Box textAlign="left" py={8}>
// //         <Typography
// //           variant="h7"
// //           style={{ textTransform: "uppercase", color: "#EF6D58" }}
// //         >
// //           One partnership. Endless logistics possibilities.
// //         </Typography>
// //         <Typography variant="h3" mt={2}>
// //           Your Next Door 4PL Partner
// //         </Typography>
// //         <Button
// //           variant="contained"
// //           style={{
// //             marginTop: "20px",
// //             backgroundColor: "#EF6D58",
// //             padding: "8px 12px",
// //             fontSize: "13px",
// //             textTransform: "capitalize",
// //             color: "#ffffff",
// //           }}
// //         >
// //           What we offer
// //         </Button>
// //       </Box>
// //     </div>
// //     <div className="imgHolder">
// //       <img src={robotImage} alt="header-image" style={{ maxWidth: "80%", height: "auto", alignItems:'right'}} />
// //     </div>
// //   </div>
// // );

// // export default Hero;

// // // import React from "react";
// // // import { Box, Typography, Button } from "@mui/material";
// // // import robotImage from "../assets/imgs/homepage-header.png";

// // // const Hero = () => (
// // //   <div
// // //     style={{
// // //       backgroundImage: "linear-gradient(180deg, #023079, #002A6C)",
// // //       color: "#ffffff",
// // //       padding: "100px 65px",
// // //     }}
// // //   >
// // //     <div className="textHolder">
// // //       <Box textAlign="left" py={8}>
// // //         <Typography
// // //           variant="h7"
// // //           style={{ textTransform: "uppercase", color: "#EF6D58" }}
// // //         >
// // //           One partnership. Endless logistics possibilities.
// // //         </Typography>
// // //         <Typography variant="h3" mt={2}>
// // //           Your Next Door 4PL Partner
// // //         </Typography>
// // //         <Button
// // //           variant="contained"
// // //           color="#ffffff"
// // //           style={{
// // //             marginTop: "20px",
// // //             backgroundColor: "#EF6D58",
// // //             padding: "8px 12px",
// // //             fontSize: "13px",
// // //             textTransform: "capitalize",
// // //           }}
// // //         >
// // //           What we offer
// // //         </Button>
// // //       </Box>
// // //     </div>
// // //     <div className="imgHolder">
// // //       <img src="robotImage" alt="header-image" />
// // //     </div>
// // //   </div>
// // // );

// // // export default Hero;
