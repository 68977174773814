import React, { useState } from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import "../assets/css/servicespage.css";
import Hero from "../components/hero/heroComponent";
import TestimonialsSlider from "../components/TestimonialsSlider";
// import Testimonials from "../components/Testimonials";
import FAQs from "../components/FAQs";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";

const ContactUsPage = () => {
  return (
    <div className="container-fluid" style={{ backgroundColor: "#E3F2FD" }}>
      {/* Hero Section */}
      <Hero heading="Contact Us" />
      {/* Testimonials Section */}
      <ContactForm />
      {/* Testimonials Section */}
      <Box
        sx={{ backgroundColor: "#001d3d", color: "#FFFFFF", padding: "60px" }}
      >
        <TestimonialsSlider />
        {/* <Testimonials /> */}
      </Box>

      {/* FAQs Section */}
      <Box sx={{ padding: "60px 100px" }}>
        <FAQs />
      </Box>
      {/* Footer Section */}
      <Footer />
    </div>
  );
};

export default ContactUsPage;
