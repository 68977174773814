import React from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/imgs/logo-white.png";

const Navbar = ({ onScrollToAboutUs }) => {
  const navigate = useNavigate();

  const handleAboutUsClick = () => {
    navigate("/"); // Navigate to the Home page
    setTimeout(() => {
      onScrollToAboutUs(); // Scroll to the About Us section after navigation
    }, 100); // Small delay to ensure navigation completes
  };

  return (
    <AppBar
      position="fixed"
      style={{
        backgroundColor: "#023079",
        fontFamily: "Epilogue, sans-serif",
        paddingTop: "15px",
        paddingBottom: "10px",
      }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        {/* Logo */}
        <Box display="flex" alignItems="center">
          <img
            src={logo}
            alt="SYM International Logo"
            style={{ height: 40, marginRight: "10px" }}
          />
        </Box>

        {/* Nav Links */}
        <Box
          className="grid-container"
          alignItems="center"
          style={{
            color: "#fff",
            fontSize: "13px",
            textTransform: "capitalize",
            marginRight: "10px",
          }}
        >
          <Link
            to="/"
            style={{ textDecoration: "none", color: "whitesmoke" }}
          >
            <Button color="inherit" style={{ margin: "0px 30px" }}>
              Home
            </Button>
          </Link>
          <Link
            to="/aboutus"
            style={{ textDecoration: "none", color: "whitesmoke" }}
          >
            <Button
              color="inherit"
              style={{ margin: "0px 30px" }}
              // onClick={handleAboutUsClick}
            >
              About Us
            </Button>
          </Link>

          <Link
            to="/services"
            style={{ textDecoration: "none", color: "whitesmoke" }}
          >
            <Button color="inherit" style={{ margin: "0px 30px" }}>
              Services
            </Button>
          </Link>
          {/* <Link
            to="#testimonials"
            style={{ textDecoration: "none", color: "whitesmoke" }}
          >
            <Button color="inherit" style={{ margin: "0px 30px" }}>
              Testimonials
            </Button>
          </Link>
          <Link
            to="#faqs"
            style={{ textDecoration: "none", color: "whitesmoke" }}
          >
            <Button color="inherit" style={{ margin: "0px 30px" }}>
              FAQs
            </Button>
          </Link> */}
          <Link
            to="/blogs"
            style={{ textDecoration: "none", color: "whitesmoke" }}
          >
            <Button color="inherit" style={{ margin: "0px 30px" }}>
              Blogs
            </Button>
          </Link>
          <Link
            to="/contactus"
            style={{ textDecoration: "none", color: "whitesmoke" }}
          >
            <Button
              variant="outlined"
              style={{
                color: "#fff",
                border: "0.2px solid #fff",
                fontSize: "13px",
                marginLeft: "15px",
                textTransform: "uppercase",
                padding: "8px 18px",
                color: "whitesmoke",
              }}
            >
              Contact Us
            </Button>
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
// import { Link } from 'react-router-dom';
// import logo from '../assets/imgs/logo-white.png';

// const Navbar = () => {
//   return (
//     <AppBar position="fixed" style={{ backgroundColor: '#023079', fontFamily: 'Epilogue, sans-serif', paddingTop:'15px', paddingBottom:'10px'}}>
//       <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
//         {/* Logo */}
//         <Box display="flex" alignItems="center">
//           <img src={logo} alt="SYM International Logo" style={{ height: 40, marginRight: '10px' }} />
//         </Box>

//         {/* Nav Links */}
//         <Box className="grid-container" alignItems="center"
//             style={{
//                 color: '#fff',
//                 fontSize: '13px',
//                 textTransform: 'capitilize',
//                 marginRight:'10px',

//                 }}
//         >
//           <Link to="/" style={{ textDecoration: 'none', color:'whitesmoke' }}>
//             <Button color="inherit" style={{margin:'0px 30px'}}>Home</Button>
//           </Link>
//           <Link to="#about-us" style={{ textDecoration: 'none', color:'whitesmoke' }}>
//             <Button color="inherit" style={{margin:'0px 30px'}}>About Us</Button>
//             </Link>
//             <Link to="/services" style={{ textDecoration: 'none', color:'whitesmoke' }}>
//             <Button color="inherit" style={{margin:'0px 30px'}} >Services</Button>
//             </Link>
//             <Link to="#testimonials" style={{ textDecoration: 'none', color:'whitesmoke' }}>
//             <Button color="inherit" style={{margin:'0px 30px'}}>Testimonials</Button>
//             </Link>
//             <Link to="#faqs" style={{ textDecoration: 'none', color:'whitesmoke' }}>
//             <Button color="inherit" style={{margin:'0px 30px'}}>FAQs</Button>
//             </Link>
//             <Link to="/blogs" style={{ textDecoration: 'none', color:'whitesmoke' }}>
//             <Button color="inherit" style={{margin:'0px 30px'}}>Blogs</Button>
//             </Link>
// <Link to="/contactus" style={{ textDecoration: 'none', color:'whitesmoke' }}>
// <Button
//     variant="outlined"
//     style={{
//     color: '#fff',
//     border: '0.2px solid #fff',
//     fontSize: '13px',
//     marginLeft: '15px',
//     textTransform: 'uppercase',
//     padding:'8px 18px',
//     color:'whitesmoke'
//     }}
// >
//     Contact
// </Button>
// </Link>
//         </Box>
//       </Toolbar>
//     </AppBar>
//   );
// };

// export default Navbar;
