import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Avatar,
  Button,
  Grid,
} from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import SeeAllTestimonials from "./modals/SeeAllTestimonials"; // Import the modal component
import "../assets/css/Testimonials.css"

// Sample testimonials data
const testimonials = [
  {
    name: "Alan Martí",
    title: "Supply Chain Manager, Retail Industry",
    avatar: "/path/to/alan-avatar.jpg",
    rating: 5,
    quote: "Reliable, efficient, and always ahead!",
    description:
      "Working with this 4PL partner has transformed our logistics. They handle everything seamlessly, from inventory to last-mile delivery. We've seen a notable reduction in costs and an increase in our supply chain efficiency. Truly a game-changer!",
  },
  {
    name: "Richardo Kann",
    title: "Operations Director, E-commerce",
    avatar: "/path/to/richardo-avatar.jpg",
    rating: 5,
    quote: "A trustworthy partner for our growing business.",
    description:
      "As our business expanded, logistics became more complex. Our 4PL provider stepped in with customized solutions that streamlined our entire operation. Their support has been crucial for our growth, and we couldn’t be happier.",
  },
  {
    name: "Graham Griffiths",
    title: "Logistics Coordinator, Manufacturing Sector",
    avatar: "/path/to/graham-avatar.jpg",
    rating: 5,
    quote: "End-to-end solutions that deliver results.",
    description:
      "This team provides the insight and oversight we needed to optimize our logistics. They’ve taken the stress out of managing multiple vendors, and we now have better control over our processes. Excellent service and results!",
  },
  {
    name: "Maria Trofimova",
    title: "Head of Procurement, Healthcare Industry",
    avatar: "/path/to/maria-avatar.jpg",
    rating: 5,
    quote: "Flexible, responsive, and proactive.",
    description:
      "Our supply chain requirements change rapidly, and this 4PL provider adapts effortlessly. They proactively address issues before they arise, ensuring smooth operations. Their commitment to our success is evident in every interaction.",
  },
];

const Testimonials = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <Box sx={{ backgroundColor: "none", py: 20, px: 15 }}>
      {/* Section Header */}
      <Box textAlign="center" mb={4}>
        <Typography className="testimonials-subtitle" sx={{ color: "#e57373", textTransform: "uppercase" }}>
          Testimonials
        </Typography>
        <Typography variant="h3" sx={{ fontWeight: "800", color: "#3C1D00", paddingBottom:'45px' }}>
          What our customers say about us
        </Typography>
      </Box>

      {/* Testimonials Grid */}
      <Grid container spacing={3} justifyContent="center">
        {testimonials.slice(0, 4).map((testimonial, index) => (
        //   <Grid item xs={12} sm={12} md={6} lg={6} key={index} style={{margin:2}}>
        //     <Card
        //       sx={{
        //         height: "100%",
        //         boxShadow: 3,
        //         borderRadius: 2,
        //         display: "flex",
        //         flexDirection: "column",
        //         justifyContent: "space-between",
        //         padding: 2,
        //       }}
        //     >
        //       <CardContent>
        //         {/* Rating */}
        //         <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        //           {Array(testimonial.rating)
        //             .fill()
        //             .map((_, i) => (
        //               <StarIcon key={i} sx={{ color: "#FFD700", fontSize: 20 }} />
        //             ))}
        //         </Box>

        //         {/* Quote */}
        //         <Typography variant="h6" fontWeight="bold" sx={{ color: "#3C1D00", mb: 1 }}>
        //           {testimonial.quote}
        //         </Typography>

        //         {/* Description */}
        //         <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        //           {testimonial.description}
        //         </Typography>

        //         {/* User Info */}
        //         <Box display="flex" alignItems="center" mt={2}>
        //           <Avatar src={testimonial.avatar} alt={testimonial.name} sx={{ mr: 2 }} />
        //           <Box>
        //             <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#3C1D00" }}>
        //               {testimonial.name}
        //             </Typography>
        //             <Typography variant="body2" color="text.secondary">
        //               {testimonial.title}
        //             </Typography>
        //           </Box>
        //         </Box>
        //       </CardContent>
        //     </Card>
        //   </Grid>

        <Grid item xs={12} md={6} key={index}>
        <Card
          sx={{
            boxShadow: 2,
            borderRadius: 2,
            padding: 2,
          }}
        >
          <CardContent>
            {/* Rating */}
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              {Array(testimonial.rating)
                .fill()
                .map((_, i) => (
                  <StarIcon key={i} sx={{ color: "#FFD700", fontSize: 20 }} />
                ))}
            </Box>

            {/* Quote */}
            <Typography variant="h6" fontWeight="bold" sx={{ mb: 1, color: "#3C1D00" }}>
              {testimonial.quote}
            </Typography>

            {/* Description */}
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              {testimonial.description}
            </Typography>

            {/* User Info */}
            <Box display="flex" alignItems="center">
              <Avatar src={testimonial.avatar} alt={testimonial.name} sx={{ mr: 2 }} />
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#3C1D00" }}>
                  {testimonial.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {testimonial.title}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Grid>
        ))}
      </Grid>

      {/* "See All" Button */}
      <Box textAlign="center" mt={4}>
        <Button
          variant="outlined"
          sx={{ color: "#3C1D00", borderColor: "#3C1D00" }}
          onClick={handleOpenModal}
        >
          See All
        </Button>
      </Box>

      {/* Modal Component */}
      <SeeAllTestimonials open={openModal} onClose={handleCloseModal} testimonials={testimonials} />
    </Box>
  );
};

export default Testimonials;






// import React from "react";
// import {
//   Box,
//   Typography,
//   Card,
//   CardContent,
//   Avatar,
//   Button,
//   Grid,
// } from "@mui/material";
// import StarIcon from "@mui/icons-material/Star";

// // Sample testimonials data
// const testimonials = [
//   {
//     name: "Alan Martí",
//     title: "Supply Chain Manager, Retail Industry",
//     avatar: "/path/to/alan-avatar.jpg",
//     rating: 5,
//     quote: "Reliable, efficient, and always ahead!",
//     description:
//       "Working with this 4PL partner has transformed our logistics. They handle everything seamlessly, from inventory to last-mile delivery. We've seen a notable reduction in costs and an increase in our supply chain efficiency. Truly a game-changer!",
//   },
//   {
//     name: "Richardo Kann",
//     title: "Operations Director, E-commerce",
//     avatar: "/path/to/richardo-avatar.jpg",
//     rating: 5,
//     quote: "A trustworthy partner for our growing business.",
//     description:
//       "As our business expanded, logistics became more complex. Our 4PL provider stepped in with customized solutions that streamlined our entire operation. Their support has been crucial for our growth, and we couldn’t be happier.",
//   },
//   {
//     name: "Graham Griffiths",
//     title: "Logistics Coordinator, Manufacturing Sector",
//     avatar: "/path/to/graham-avatar.jpg",
//     rating: 5,
//     quote: "End-to-end solutions that deliver results.",
//     description:
//       "This team provides the insight and oversight we needed to optimize our logistics. They’ve taken the stress out of managing multiple vendors, and we now have better control over our processes. Excellent service and results!",
//   },
//   {
//     name: "Maria Trofimova",
//     title: "Head of Procurement, Healthcare Industry",
//     avatar: "/path/to/maria-avatar.jpg",
//     rating: 5,
//     quote: "Flexible, responsive, and proactive.",
//     description:
//       "Our supply chain requirements change rapidly, and this 4PL provider adapts effortlessly. They proactively address issues before they arise, ensuring smooth operations. Their commitment to our success is evident in every interaction.",
//   },
// ];

// const Testimonials = () => {
//   return (
//     <Box sx={{ backgroundColor: "#E3F2FD", py: 6, px: 2 }}>
//       {/* Section Header */}
//       <Box textAlign="center" mb={4}>
//         <Typography variant="h6" sx={{ color: "#e57373", textTransform: "uppercase" }}>
//           Testimonials
//         </Typography>
//         <Typography variant="h4" sx={{ fontWeight: "bold", color: "#3C1D00" }}>
//           What our customers say about us
//         </Typography>
//       </Box>

//       {/* Testimonials Grid */}
//       <Grid container spacing={3} justifyContent="center">
//         {testimonials.map((testimonial, index) => (
//           <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
//             <Card
//               sx={{
//                 height: "100%",
//                 boxShadow: 3,
//                 borderRadius: 2,
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//                 padding: 2,
//               }}
//             >
//               <CardContent>
//                 {/* Rating */}
//                 <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
//                   {Array(testimonial.rating)
//                     .fill()
//                     .map((_, i) => (
//                       <StarIcon key={i} sx={{ color: "#FFD700", fontSize: 20 }} />
//                     ))}
//                 </Box>

//                 {/* Quote */}
//                 <Typography variant="h6" fontWeight="bold" sx={{ color: "#3C1D00", mb: 1 }}>
//                   {testimonial.quote}
//                 </Typography>

//                 {/* Description */}
//                 <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
//                   {testimonial.description}
//                 </Typography>

//                 {/* User Info */}
//                 <Box display="flex" alignItems="center" mt={2}>
//                   <Avatar src={testimonial.avatar} alt={testimonial.name} sx={{ mr: 2 }} />
//                   <Box>
//                     <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#3C1D00" }}>
//                       {testimonial.name}
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       {testimonial.title}
//                     </Typography>
//                   </Box>
//                 </Box>
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>

//       {/* "See All" Button */}
//       <Box textAlign="center" mt={4}>
//         <Button variant="outlined" sx={{ color: "#3C1D00", borderColor: "#3C1D00" }}>
//           See All
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default Testimonials;





// import { Container, Row, Col } from 'react-grid-system';
// import { Button } from "@mui/material";
// import '../assets/css/Testimonials.css';

// const testimonialsData = [
//     {
//         quote: "Reliable, efficient, and always ahead!",
//         feedback: "Working with this 4PL partner has transformed our logistics. They handle everything seamlessly, from inventory to last-mile delivery. We’ve seen a notable reduction in costs and an increase in our supply chain efficiency. Truly a game-changer!",
//         name: "Alan Marti",
//         title: "Supply Chain Manager, Retail Industry",
//         image: "alan.jpg",
//     },
//     {
//         quote: "A trustworthy partner for our growing business.",
//         feedback: "As our business expanded, logistics became more complex. Our 4PL provider stepped in with customized solutions that streamlined our entire operation. Their support has been crucial for our growth, and we couldn’t be happier.",
//         name: "Richardo Kann",
//         title: "Operations Director, E-commerce",
//         image: "richardo.jpg",
//     },
//     {
//         quote: "End-to-end solutions that deliver results.",
//         feedback: "This team provides the insight and oversight we needed to optimize our logistics. They’ve taken the stress out of managing multiple vendors, and we now have better control over our processes. Excellent service and results!",
//         name: "Graham Griffiths",
//         title: "Logistics Coordinator, Manufacturing Sector",
//         image: "graham.jpg",
//     },
//     {
//         quote: "Flexible, responsive, and proactive.",
//         feedback: "Our supply chain requirements change rapidly, and this 4PL provider adapts effortlessly. They proactively address issues before they arise, ensuring smooth operations. Their commitment to our success is evident in every interaction.",
//         name: "Maria Trofimova",
//         title: "Head of Procurement, Healthcare Industry",
//         image: "maria.jpg",
//     },
// ];

// const Testimonials = () => {
//     const [openModal, setOpenModal] = useState(false);

// const handleOpenModal = () => setOpenModal(true);
// const handleCloseModal = () => setOpenModal(false);

//     return (
//         <Container >
//         <Container>
//             <Row className="testimonials-header" style={{ textAlign: 'center', marginBottom: '40px' }}>
//                 <Col>
//                     <p className="testimonials-subtitle" style={{ opacity: 0.8 }}>TESTIMONIALS</p>
//                     <h2 className="testimonials-title" style={{ fontSize: '2rem', fontWeight: 'bold' }}>
//                         What our customers say about us
//                     </h2>
//                 </Col>
//             </Row>
//         </Container>
//         <Container>
//             <Row>
//                 {testimonialsData.map((testimonial, index) => (
//                     <Col md={6} sm={12} key={index} className="testimonial-col" >
//                         <div className="testimonial-card" style={{
//                             background: '#ffffff',
//                             borderRadius: '8px',
//                             padding: '20px',
//                             boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//                             textAlign: 'left'
//                         }}>
//                             <div className="testimonial-quote" style={{ marginBottom: '20px' }}>
//                                 <span>⭐️⭐️⭐️⭐️⭐️</span>
//                                 <h3 style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{testimonial.quote}</h3>
//                                 <p style={{ fontSize: '1rem', color: '#555' }}>{testimonial.feedback}</p>
//                             </div>
//                             <div className="testimonial-author" style={{ display: 'flex', alignItems: 'center' }}>
//                                 <img src={testimonial.image} alt={testimonial.name} className="author-image" style={{
//                                     width: '50px',
//                                     height: '50px',
//                                     borderRadius: '50%',
//                                     marginRight: '10px'
//                                 }} />
//                                 <div className="author-details">
//                                     <p className="author-name" style={{ fontWeight: 'bold' }}>{testimonial.name}</p>
//                                     <p className="author-title" style={{ fontSize: '0.9rem', color: '#777' }}>{testimonial.title}</p>
//                                 </div>
//                             </div>
//                         </div>
//                     </Col>
//                 ))}
//             </Row>

//             <Row className="testimonials-footer" style={{ textAlign: 'center', marginTop: '40px' }}>
//                 <Col>
//                     <Button
//                         variant="contained"
//                         className="see-all-button"
//                         onClick={handleOpenModal}
//                         style={{
//                             backgroundColor: "#ffffff",
//                             color: "#333333",
//                             fontWeight: "bold",
//                             padding: "10px 20px",
//                             boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//                             textTransform: "uppercase",
//                         }}
//                     >
//                         See All

// {/* // <SeeAllTestimonials open={openModal} onClose={handleCloseModal} testimonials={testimonials} /> */}

//                     </Button>
//                 </Col>
//             </Row>
//         </Container>
//         </Container>
//     );
// };

// export default Testimonials;
