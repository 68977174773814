import React, { useRef } from "react";
import Hero from "../components/Hero";
import About from "../components/AboutUs";
import Services from "../components/Services";
import ContactForm from "../components/ContactForm";
import MainBlog from "../components/MainBlog";
import Footer from "../components/Footer";
import FAQs from "../components/FAQs";
import Testimonials from "../components/Testimonials";
import Navbar from "../components/Navbar"; // Ensure Navbar is imported

const Home = () => {
  const aboutUsRef = useRef(null);

  const scrollToAboutUs = () => {
    if (aboutUsRef.current) {
      aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <Navbar onScrollToAboutUs={scrollToAboutUs} /> {/* Pass the function here */}
      <Hero />
      {/* <div ref={aboutUsRef} id="about-us"> */}
        <About />
      {/* </div> */}
      <div id="services">
        <Services />
      </div>
      <div id="testimonials" style={{ backgroundColor: "#E3F2FD" }}>
        <Testimonials />
      </div>
      <div id="faqs">
        <FAQs />
      </div>
      <div id="blog">
        <MainBlog />
      </div>
      <div id="contact-form">
        <ContactForm />
      </div>
      <Footer />
    </div>
  );
};

export default Home;




// import React, { useRef } from "react";
// import Hero from "../components/Hero";
// import About from "../components/AboutUs";
// import Services from "../components/Services";
// import ContactForm from "../components/ContactForm";
// import MainBlog from "../components/MainBlog";
// import Footer from "../components/Footer";
// import FAQs from "../components/FAQs";
// import Testimonials from "../components/Testimonials";

// const Home = () => {
//   const aboutUsRef = useRef(null);

//   const scrollToSection = (ref) => {
//     if (ref && ref.current) {
//       ref.current.scrollIntoView({ behavior: "smooth" });
//     }
//   };

//   return (
//     <div>
//       {/* <Navbar /> */}
//       <Hero />
//       <div ref={aboutUsRef} id="about-us">
//         <About />
//       </div>
//       <div id="services">
//         <Services />
//       </div>
//       <div id="testimonials" style={{ backgroundColor: "#E3F2FD" }}>
//         <Testimonials />
//       </div>
//       <div id="faqs">
//         <FAQs />
//       </div>
//       <div id="blog">
//         <MainBlog />
//       </div>
//       <div id="contact-form">
//         <ContactForm />
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default Home;





// // import React, { useRef } from "react";
// // import Hero from "../components/Hero";
// // import About from "../components/AboutUs";
// // import Services from "../components/Services";
// // import ContactForm from "../components/ContactForm";
// // import MainBlog from "../components/MainBlog";
// // import Footer from "../components/Footer";
// // import FAQs from "../components/FAQs";
// // import Testimonials from "../components/Testimonials";

// // const Home = () => {
  
// //   return (
// //   <div>
// //     {/* <Navbar /> */}
// //     <Hero />
// //     <div id="about-us">
// //       <About />
// //     </div>
// //     <div id="services">
// //       <Services />
// //     </div>
// //     <div id="testimonials" style={{backgroundColor: "#E3F2FD"}}>
// //       <Testimonials />
// //     </div>
// //     <div id="faqs">
// //       <FAQs />
// //     </div>
// //     <div id="blog">
// //       <MainBlog />
// //     </div>
// //     <div id="contact-form">
// //     <ContactForm />
// //     </div>
// //     <Footer />
// //   </div>
// // );

// // }

// // export default Home;
